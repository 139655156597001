<template>
  <div class="standart">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="standart_title">
            <span></span>{{ 'standart' | translate }}
              <div class="select">
                  <Select v-model="model1" style="width:200px" aria-placeholder="Salbar" :placeholder="'select' | translate">
                      <Option v-for="item in salbars" :value="item.value" :key="item.value">{{ item.label }}</Option>
                  </Select>
              </div>
          </div>
          <div class="standart_table">
            <table class="table">
              <thead>
              <tr>
                <th scope="col">№</th>
                <th scope="col">{{ 'standart' | translate }}</th>
                <th scope="col"></th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td>1</td>
                <td>Сууц өмчлөгчдийн холбооны эрх зүйн байдал, нийтийн зориулалттай орон сууцны байшингийн дундын өмчлөлийн эд хөрөнгийн тухай / 2003 он /</td>
                <td><div class="view"><a href="">{{ 'watch' | translate }}</a></div></td>
              </tr>
              <tr>
                <td>2</td>
                <td>Сууц өмчлөгчдийн холбооны эрх зүйн байдал, нийтийн зориулалттай орон сууцны байшингийн дундын өмчлөлийн эд хөрөнгийн тухай / 2003 он /</td>
                <td><div class="view"><a href="">{{ 'watch' | translate }}</a></div></td>
              </tr>
              <tr>
                <td>3</td>
                <td>Сууц өмчлөгчдийн холбооны эрх зүйн байдал, нийтийн зориулалттай орон сууцны байшингийн дундын өмчлөлийн эд хөрөнгийн тухай / 2003 он /</td>
                <td><div class="view"><a href="">{{ 'watch' | translate }}</a></div></td>
              </tr>
              <tr>
                <td>4</td>
                <td>Сууц өмчлөгчдийн холбооны эрх зүйн байдал, нийтийн зориулалттай орон сууцны байшингийн дундын өмчлөлийн эд хөрөнгийн тухай / 2003 он /</td>
                <td><div class="view"><a href="">{{ 'watch' | translate }}</a></div></td>
              </tr>
              <tr>
                <td>5</td>
                <td>Сууц өмчлөгчдийн холбооны эрх зүйн байдал, нийтийн зориулалттай орон сууцны байшингийн дундын өмчлөлийн эд хөрөнгийн тухай / 2003 он /</td>
                <td><div class="view"><a href="">{{ 'watch' | translate }}</a></div></td>
              </tr>
              <tr>
                <td>6</td>
                <td>Сууц өмчлөгчдийн холбооны эрх зүйн байдал, нийтийн зориулалттай орон сууцны байшингийн дундын өмчлөлийн эд хөрөнгийн тухай / 2003 он /</td>
                <td><div class="view"><a href="">{{ 'watch' | translate }}</a></div></td>
              </tr>
              <tr>
                <td>6</td>
                <td>Сууц өмчлөгчдийн холбооны эрх зүйн байдал, нийтийн зориулалттай орон сууцны байшингийн дундын өмчлөлийн эд хөрөнгийн тухай / 2003 он /</td>
                <td><div class="view"><a href="">{{ 'watch' | translate }}</a></div></td>
              </tr>
              <tr>
                <td>7</td>
                <td>Сууц өмчлөгчдийн холбооны эрх зүйн байдал, нийтийн зориулалттай орон сууцны байшингийн дундын өмчлөлийн эд хөрөнгийн тухай / 2003 он /</td>
                <td><div class="view"><a href="">{{ 'watch' | translate }}</a></div></td>
              </tr>
              <tr>
                <td>8</td>
                <td>Сууц өмчлөгчдийн холбооны эрх зүйн байдал, нийтийн зориулалттай орон сууцны байшингийн дундын өмчлөлийн эд хөрөнгийн тухай / 2003 он /</td>
                <td><div class="view"><a href="">{{ 'watch' | translate }}</a></div></td>
              </tr>
              <tr>
                <td>9</td>
                <td>Сууц өмчлөгчдийн холбооны эрх зүйн байдал, нийтийн зориулалттай орон сууцны байшингийн дундын өмчлөлийн эд хөрөнгийн тухай / 2003 он /</td>
                <td><div class="view"><a href="">{{ 'watch' | translate }}</a></div></td>
              </tr>
              <tr>
                <td>10</td>
                <td>Сууц өмчлөгчдийн холбооны эрх зүйн байдал, нийтийн зориулалттай орон сууцны байшингийн дундын өмчлөлийн эд хөрөнгийн тухай / 2003 он /</td>
                <td><div class="view"><a href="">{{ 'watch' | translate }}</a></div></td>
              </tr>
              <tr>
                <td>11</td>
                <td>Сууц өмчлөгчдийн холбооны эрх зүйн байдал, нийтийн зориулалттай орон сууцны байшингийн дундын өмчлөлийн эд хөрөнгийн тухай / 2003 он /</td>
                <td><div class="view"><a href="">{{ 'watch' | translate }}</a></div></td>
              </tr>
              <tr>
                <td>12</td>
                <td>Сууц өмчлөгчдийн холбооны эрх зүйн байдал, нийтийн зориулалттай орон сууцны байшингийн дундын өмчлөлийн эд хөрөнгийн тухай / 2003 он /</td>
                <td><div class="view"><a href="">{{ 'watch' | translate }}</a></div></td>
              </tr>

              </tbody>
            </table>
          </div>
          <div class="pagenation">
            <Page :total="40" size="small" />
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>

export default {

  name: "standart",
  components: {

  },
  data () {
    return {
      salbars: [
        {
          value: '1',
          label: 'Бодлогын баримт бичиг 1'
        },
        {
          value: '2',
          label: 'Бодлогын баримт бичиг 2'
        },
        {
          value: '3',
          label: 'Бодлогын баримт бичиг 3'
        },
      ],
      model1: ''
    }
  }


}




</script>

<style scoped>

</style>